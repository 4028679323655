// React
import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import MigrationUploadSegment from '../../segments/content-upload-segments/MigrationUploadSegment'
import { PPPTargetExpectedColumns } from "../../state/constants/TemplateColumns";

// UI and Styling
import {
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Box
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import styles from "./ViewActivityGroupPage.module.scss";
import MUIDataTable from "mui-datatables";
import AddIcon from "@material-ui/icons/Add";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

// Actions
import {
  createActivity,
  getActivityById,
  getDropdownLookups,
  updateActivity,
  replaceActivityTargets,
  getActivityTargetsAsCSV
} from "../../state/actions/ActivityActions";
import { getLoggedInUserByEmail } from "../../state/actions/UserActions";

// Components
import AlertDialog from "../../components/alert-dialog/AlertDialog";
import CustomCard from "../../components/custom-card/CustomCard";
import DropdownSelect from "../../components/dropdown-select/DropdownSelect";
import HeaderBlock from "../../components/header-block/HeaderBlock";
import { Loading } from "../../components/loading/Loading";
import PageContainer from "../../components/page-container/PageContainer";

// Models
import { ActivityModel } from "../../models/ActivityModel";


// Other
import DateFnsUtils from "@date-io/date-fns";
import { isValid, format, formatISO, getYear, getMonth, getDate } from "date-fns";
import { FormType } from "../../state/constants/FormType";
import { compareDates, validateField } from "../../helpers/validation";
import Modal from "../../components/dialog/Modal";

const ViewActivityPage = (props) => {
  const currentActivity = useSelector(
    (state) => state.ActivityReducer.currentActivity
  );
  const lookupData = useSelector((state) => state.ActivityReducer.lookupData);
  const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);

  const dispatch = useDispatch();

  //Setup State
  const [loading, setLoading] = useState(false);
  const [formProperties, setFormProperties] = useState(ActivityModel);
  const [activity, setActivity] = useState({});
  const [uneditedActivity, setUneditedActivity] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isExistingActivity, setIsExistingActivity] = useState(true);
  const [activityId, setActivityId] = useState("");
  const [dataChanged, setDataChanged] = useState(false);
  const [formEdited, setFormEdited] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(true);
  const [isRanging, setIsRanging] = useState(false);
  const [cancelEditingAlertOpen, setCancelEditingAlertOpen] = useState(false);
  const [
    activityDataTypesLookupData,
    setActivityDataTypesLookupData
  ] = useState([]);
  const [showTargetUploadModal, setShowTargetUploadModal] = useState(false)
  const [
    activityFrequenciesLookupData,
    setActivityFrequenciesLookupData
  ] = useState([]);
  const [activityTypesLookupData, setActivityTypesLookupData] = useState([]);

  const activityGroupId = props.match.params.activityGroupId;
  const activityGroupName = new URLSearchParams(props.location.search).get(
    "activityGroup"
  );
  
  // Stuff for the uploader modal on this page.
  const [csvData, setCsvData] = useState({});
  const [CSVInvalid, setCSVInvalid] = useState(false);
  const [targetsEdited, setTargetsEdited] = useState(false);



  const setUpData = () => {
    setIsExistingActivity(props.type != null && props.type === FormType.VIEW);

    //Get all dropdown lookup data
    dispatch(
      getDropdownLookups(loggedInUser.territory === "au" ? 1 : 2, () => { })
    );

    if (props.type != null && props.type === FormType.VIEW) {
      dispatch(
        getActivityById(props.match.params.activityId, () => {
          setLoading(false);
          setActivityId(props.match.params.activityId);
        })
      );
    } else {
      dispatch(
        getActivityById(undefined, () => {
          setIsEditing(true);
          setLoading(false);
        })
      );
    }
  };

  //Use Effects
  useEffect(() => {
    setLoading(true);
    if (!loggedInUser || Object.keys(loggedInUser).length === 0) {
      dispatch(
        getLoggedInUserByEmail(() => {
          setUpData();
        })
      );
    } else {
      setUpData();
    }
  }, []);

  useEffect(() => {
    setActivityDataTypesLookupData(lookupData.ActivityDataTypes);
    setActivityFrequenciesLookupData(lookupData.ActivityFrequencies);
    setActivityTypesLookupData(lookupData.ActivityTypes);
  }, [lookupData]);

  useEffect(() => {
    setActivity(currentActivity);

    if (
      activityDataTypesLookupData !== undefined &&
      activityDataTypesLookupData.length > 0
    ) {
      const dataType = activityDataTypesLookupData.find(
        (data) =>
          data.Id.toString() === currentActivity.activityDataTypeId.toString()
      );

      if (
        dataType !== undefined &&
        dataType.DisplayName.startsWith("Ranging")
      ) {
        setIsRanging(true);
      } else {
        setIsRanging(false);
      }
    }
  }, [currentActivity]);

  // Whenever a change to any of the form fields happens this method is called to update the state of the activity
  // This is so the values in the form fields updates correctly on change.
  const handleInputChange = (name, value) => {
    let localData = Object.assign({}, activity);

    localData[name] = value;

    setActivity(localData);

    if (!formEdited) {
      setFormEdited(true);
    }
  };


  const handleDownloadCSVRequest = (id) => {
    dispatch(
      getActivityTargetsAsCSV(id, () => {
        // Callback, doesn't do anything. Might need to.
      })
    )
  };

  /*
     Generic validation to perform on all field types
    */
  const checkGenericField = (key, required) => {
    return validateField(
      required,
      activity[key],
      formProperties[key].type,
      formProperties[key].label
    );
  };

  /*
      Validate the form before it is submitted.
      Dates not only need to be checked for validity they also need to be compared with each other.
   */
  const validateForm = () => {
    if (!submitEnabled) {
      return;
    }
    //Prevent the user from button mashing
    setSubmitEnabled(false);

    let numErrors = 0;
    let localProperties = formProperties;

    Object.keys(formProperties).forEach((key) => {
      let errorMessage = "";

      if (key === "startDate") {
        errorMessage = checkGenericField(key, formProperties[key].required);

        if (errorMessage === "") {
          let isD1Valid = isValid(new Date(activity["startDate"]));
          let isD2Valid = isValid(new Date(activity["endDate"]));
          if (isD1Valid && isD2Valid) {
            errorMessage = compareDates(
              new Date(activity[key]).toISOString(),
              new Date(activity["endDate"]).toISOString(),
              formProperties[key].label,
              formProperties["endDate"].label,
              false
            );
          }
        }
      } else if (key === "endDate") {
        errorMessage = checkGenericField(key, formProperties[key].required);

        if (errorMessage === "") {
          let isD1Valid = isValid(new Date(activity["startDate"]));
          let isD2Valid = isValid(new Date(activity["endDate"]));
          if (isD1Valid && isD2Valid) {
            errorMessage = compareDates(
              new Date(activity["startDate"]).toISOString(),
              new Date(activity["endDate"]).toISOString(),
              formProperties["startDate"].label,
              formProperties[key].label,
              true
            );
          }
        }
      } else {
        errorMessage = checkGenericField(key, formProperties[key].required);
      }

      if (errorMessage.length > 0) {
        numErrors++;
      }

      localProperties[key].errorMessage = errorMessage;
    });

    //TODO: Find a better way to deal with form changes. This just forces a re-render after validation
    setDataChanged(!dataChanged);

    if (numErrors > 0) {
      setSubmitEnabled(true);
      return false;
    } else {
      return true;
    }
  };

  /* Transform the activity group data into the form that is required by the backend */
  const createRequestBody = (isCreate) => {
    let requestBody = {
      Activity: {
        StartDate: activity.startDate,
        EndDate: activity.endDate,
        Sequence: activity.sequence,
        IsRequired: activity.required,
        IsOneTimeAnswer: activity.oneTimeAnswer,
        IsActive: activity.active,
        ActivityGroupId: activityGroupId,
        ActivityTypeId: activity.activityTypeId,
        Name: activity.name,
        ActivityDataTypeId: activity.activityDataTypeId,
        ActivityFrequencyId: activity.activityFrequencyId,
        Min: activity.min,
        Max: activity.max,
        ClientId: activity.clientId
      }
    };

    if (isCreate) {
      requestBody.Activity.CreatedBy = loggedInUser.userId;
      requestBody.Activity.ModifiedBy = loggedInUser.userId;
    } else {
      requestBody.Activity.ModifiedBy = loggedInUser.userId;
    }

    return requestBody;
  };

  // When submit is clicked, validate the data and if ok call the create action
  const handleSubmitClicked = () => {
    if (validateForm()) {
      dispatch(
        createActivity(createRequestBody(true), (success, id) => {
          setIsEditing(false);
          props.history.push(`/activity-groups/${activityGroupId}/${id}`);
        })
      );
    }
  };

  // When submit is clicked, validate the data and if ok call the create action
  const handleUpdateClicked = () => {
    if (validateForm()) {
      dispatch(
        updateActivity(activityId, createRequestBody(false), () => {
          setIsEditing(false);
        })
      );
    }
  };

  /*
    Functionality for when the user clicks the cancel button on the edit or create form
  */
  const handleCancelEditButtonClicked = () => {
    if (formEdited) {
      setCancelEditingAlertOpen(true);
    } else {
      setIsEditing(false);

      if (!isExistingActivity) {
        props.history.push(`/activity-groups/${activityGroupId}`);
      }
    }
  };

  /*
Display the cancel editing alert dialog
*/
  const cancelEditingAlert = () => {
    return (
      <AlertDialog
        title={`Are you sure you want to stop editing?`}
        description={"This is permanent, and can't be undone."}
        options={[
          {
            label: "No",
            action: () => setCancelEditingAlertOpen(false)
          },
          {
            label: "Yes",
            action: () => {
              setIsEditing(false);
              setActivity(uneditedActivity);
              setCancelEditingAlertOpen(false);

              if (!isExistingActivity) {
                props.history.push(`/activity-groups/${activityGroupId}`);
              }
            }
          }
        ]}
        isOpen={cancelEditingAlertOpen}
      />
    );
  };

  const getRuleData = (targetItem) => {
    const ruleData = [];
    const excludedValues = [
      "ActivityTargetId",
      "CreatedBy",
      "CreatedDate",
      "Id",
      "IsActive",
      "ModifiedBy",
      "ModifiedDate",
      "OpportunityValue",
      "Sequence",
      "Site",
      "TargetValue"
    ];

    Object.keys(targetItem).forEach((rule) => {
      if (
        (targetItem[rule] !== "" &&
          targetItem[rule] !== undefined &&
          targetItem[rule] !== null &&
          !Array.isArray(targetItem[rule]) &&
          !excludedValues.includes(rule)) ||
        (Array.isArray(targetItem[rule]) && targetItem[rule].length > 0)
      ) {
        if (Array.isArray(targetItem[rule])) {
          ruleData.push(`${rule}: [${targetItem[rule]}]`);
        } else {
          ruleData.push(`${rule}: ${targetItem[rule]}`);
        }
      }
    });

    return ruleData.join(", ");
  };

  // Get the markup for the activity attached content lists
  const getListData = (data, tableTitle, fileName, addLabel, addAction, d_label, u_label, d_action, u_action) => {
    let listData = [];
    let columns = [];

    switch (tableTitle) {
      /* CHOICES */
      case "Choices": {
        listData =
          data != null
            ? data.map((item) => {
              return {
                name: item.Name,
                id: item.Id,
                weight: item.Weight,
                active: item.IsActive === true ? "true" : "false"
              };
            })
            : [];

        columns = [
          {
            name: "name",
            label: "Name",
            options: {
              filter: false,
              sort: true,
              customBodyRender: (value, tableMeta, updateValue) => {
                const url = `/activity-groups/${activityGroupId}/${activityId}/choice/${encodeURIComponent(
                  value.replace(/%/g, '%25')
                )}?isRanging=${isRanging}&activity=${encodeURIComponent(
                  activity.name
                )}`;
                return <Link to={url}>{value}</Link>; 
              }
            }
          },
          {
            name: "weight",
            label: "Weight",
            options: {
              filter: false,
              sort: true
            }
          },
          {
            name: "active",
            label: "Active",
            options: {
              filter: false,
              sort: true
            }
          }
        ];

        break;
      }
      /* REWARDS */
      case "Rewards": {
        listData =
          data != null
            ? data.map((item) => {
              return {
                upperRange: item.UpperRange,
                value: item.Value,
                active: item.IsActive === true ? "true" : "false"
              };
            })
            : [];

        columns = [
          {
            name: "upperRange",
            label: "Upper Range",
            options: {
              filter: false,
              sort: true,
              customBodyRender: (value, tableMeta, updateValue) => {
                const url = `/activity-groups/${activityGroupId}/${activityId}/reward/${encodeURIComponent(
                  value
                )}?activity=${encodeURIComponent(activity.name)}`;
                return <a href={url}>{value}</a>;
              }
            }
          },
          {
            name: "value",
            label: "Value",
            options: {
              filter: false,
              sort: true
            }
          },
          {
            name: "active",
            label: "Active",
            options: {
              filter: false,
              sort: true
            }
          }
        ];
        break;
      }
      /* TARGETS */
      case "Targets": {
        listData =
          data != null
            ? data.map((item) => {
              return {
                id: item.ActivityTargetId,
                rules: getRuleData(item),
                active: item.IsActive === true ? "true" : "false"
              };
            })
            : [];

        columns = [
          {
            name: "id",
            label: "Id",
            options: {
              filter: false,
              sort: true,
              customBodyRender: (value, tableMeta, updateValue) => {
                const url = `/activity-groups/${activityGroupId}/${activityId}/target/${encodeURIComponent(
                  value
                )}?activity=${encodeURIComponent(activity.name)}`;
                return <a href={url}>{value}</a>;
              }
            }
          },
          {
            name: "rules",
            label: "Rules",
            options: {
              filter: false,
              sort: true
            }
          },
          {
            name: "active",
            label: "Active",
            options: {
              filter: false,
              sort: true
            }
          }
        ];
        break;
      }
    }

    const options = {
      filter: true,
      responsive: "stacked",
      rowsPerPage: 20,
      rowsPerPageOptions: [5, 20, 50, 100, 200],
      viewColumns: false,
      filterType: "checkbox",
      selectableRows: "none",
      print: false,
      download: u_label != undefined ? false : true, // disable default when we render targets basically.
      downloadOptions: {
        filename: { fileName },
        separator: ",",
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true
        }
      }
    };

    options.customToolbar = () => {
      return (
        <Fragment>
          <Tooltip title={addLabel}>
            <IconButton onClick={addAction}>
              <AddIcon />
            </IconButton>
          </Tooltip>
          {(d_label != undefined && u_label != undefined) &&
            <Fragment>
              <Tooltip title={d_label}>
                <IconButton onClick={() => handleDownloadCSVRequest(activity.id)}>
                  <CloudDownloadIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={u_label}>
                <IconButton onClick={() => setShowTargetUploadModal(true)}>
                  <CloudUploadIcon />
                </IconButton>
              </Tooltip>
            </Fragment>
          }
        </Fragment>
      );
    };

    return (
      <MUIDataTable
        title={tableTitle}
        data={listData}
        columns={columns}
        options={options}
      />
    );
  };

  //Display Activity group and associated list of activities
  const renderData = () => {
    return (
      <Fragment>
        <Box className={styles.contentGrid}>
          <CustomCard title="Main Details" actionButton={[]}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={!isEditing}
                  id={formProperties.name.value}
                  name={formProperties.name.value}
                  label={formProperties.name.label}
                  placeholder="Enter name"
                  value={
                    activity !== undefined &&
                      Object.keys(activity).length > 0 &&
                      activity.name !== undefined
                      ? activity.name
                      : ""
                  }
                  onChange={(event) =>
                    handleInputChange(
                      formProperties.name.value,
                      event.target.value
                    )
                  }
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={formProperties.name.errorMessage !== ""}
                  helperText={formProperties.name.errorMessage}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DropdownSelect
                  disabled={!isEditing}
                  label={formProperties.activityTypeId.label}
                  handleChange={(value) => {
                    handleInputChange(
                      formProperties.activityTypeId.value,
                      value
                    );
                  }}
                  data={
                    activityTypesLookupData !== undefined &&
                      activityTypesLookupData.length > 0
                      ? activityTypesLookupData.map((t) => {
                        return { key: t.Id, value: t.DisplayName };
                      })
                      : []
                  }
                  value={activity.activityTypeId}
                  valueName={formProperties.activityTypeId.value}
                  error={formProperties.activityTypeId.errorMessage !== ""}
                  fullWidth={true}
                >
                  {formProperties.activityTypeId.errorMessage !== "" ? (
                    <FormHelperText>
                      {formProperties.activityTypeId.errorMessage}
                    </FormHelperText>
                  ) : null}
                </DropdownSelect>
              </Grid>
              <Grid item xs={12} md={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="dense"
                    disabled={!isEditing}
                    disableToolbar
                    fullWidth
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    label={formProperties.startDate.label}
                    value={
                      activity !== undefined &&
                        Object.keys(activity).length > 0 &&
                        activity.startDate !== undefined
                        ? activity.startDate
                        : new Date()
                    }
                    onChange={(date) => {
                      if (isNaN(date.getTime())) {
                        //date not valid, assume user is trying to enter the string manually.
                      } else {
                        handleInputChange(formProperties.startDate.value, formatISO(new Date(getYear(date), getMonth(date), getDate(date), 0, 0, 52)).split('+')[0] + 'Z');
                      }
                    }}
                    error={formProperties.startDate.errorMessage !== ""}
                    helperText={formProperties.startDate.errorMessage}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="dense"
                    disabled={!isEditing}
                    disableToolbar
                    fullWidth
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    label={formProperties.endDate.label}
                    value={
                      activity !== undefined &&
                        Object.keys(activity).length > 0 &&
                        activity.endDate !== undefined
                        ? activity.endDate
                        : new Date()
                    }
                    onChange={(date) => {
                      if (isNaN(date.getTime())) {
                        //date not valid, assume user is trying to enter the string manually.
                      } else {
                        handleInputChange(formProperties.endDate.value, formatISO(new Date(getYear(date), getMonth(date), getDate(date), 0, 0, 52)).split('+')[0] + 'Z');
                      }
                    }}
                    error={formProperties.endDate.errorMessage !== ""}
                    helperText={formProperties.endDate.errorMessage}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <DropdownSelect
                  disabled={!isEditing}
                  label={formProperties.activityFrequencyId.label}
                  handleChange={(value) => {
                    handleInputChange(
                      formProperties.activityFrequencyId.value,
                      value
                    );
                  }}
                  data={
                    activityFrequenciesLookupData !== undefined &&
                      activityFrequenciesLookupData.length > 0
                      ? activityFrequenciesLookupData.map((t) => {
                        return { key: t.Id, value: t.DisplayName };
                      })
                      : []
                  }
                  value={activity.activityFrequencyId}
                  valueName={formProperties.activityFrequencyId.value}
                  error={formProperties.activityFrequencyId.errorMessage !== ""}
                  fullWidth={true}
                >
                  {formProperties.activityFrequencyId.errorMessage !== "" ? (
                    <FormHelperText>
                      {formProperties.activityFrequencyId.errorMessage}
                    </FormHelperText>
                  ) : null}
                </DropdownSelect>
              </Grid>
              <Grid item xs={12} md={6}>
                <DropdownSelect
                  disabled={!isEditing}
                  label={formProperties.activityDataTypeId.label}
                  handleChange={(value) => {
                    handleInputChange(
                      formProperties.activityDataTypeId.value,
                      value
                    );

                    setIsRanging(
                      activityDataTypesLookupData
                        .find((data) => data.Id === value)
                        .DisplayName.startsWith("Ranging")
                    );
                  }}
                  data={
                    activityDataTypesLookupData !== undefined &&
                      activityDataTypesLookupData.length > 0
                      ? activityDataTypesLookupData.map((t) => {
                        return { key: t.Id, value: t.DisplayName };
                      })
                      : []
                  }
                  value={activity.activityDataTypeId}
                  valueName={formProperties.activityDataTypeId.value}
                  error={formProperties.activityDataTypeId.errorMessage !== ""}
                  fullWidth={true}
                >
                  {formProperties.activityDataTypeId.errorMessage !== "" ? (
                    <FormHelperText>
                      {formProperties.activityDataTypeId.errorMessage}
                    </FormHelperText>
                  ) : null}
                </DropdownSelect>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={!isEditing}
                  id={formProperties.min.value}
                  name={formProperties.min.value}
                  label={formProperties.min.label}
                  placeholder="Enter min"
                  value={
                    activity !== undefined &&
                      Object.keys(activity).length > 0 &&
                      activity.min !== undefined
                      ? activity.min
                      : ""
                  }
                  onChange={(event) =>
                    handleInputChange(
                      formProperties.min.value,
                      event.target.value
                    )
                  }
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={formProperties.min.errorMessage !== ""}
                  helperText={formProperties.min.errorMessage}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={!isEditing}
                  id={formProperties.max.value}
                  name={formProperties.max.value}
                  label={formProperties.max.label}
                  placeholder="Enter max"
                  value={
                    activity !== undefined &&
                      Object.keys(activity).length > 0 &&
                      activity.max !== undefined
                      ? activity.max
                      : ""
                  }
                  onChange={(event) =>
                    handleInputChange(
                      formProperties.max.value,
                      event.target.value
                    )
                  }
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={formProperties.max.errorMessage !== ""}
                  helperText={formProperties.max.errorMessage}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={!isEditing}
                  id={formProperties.sequence.value}
                  name={formProperties.sequence.value}
                  label={formProperties.sequence.label}
                  placeholder="Enter sequence"
                  value={
                    Object.keys(activity).length > 0 &&
                      activity.sequence !== undefined
                      ? activity.sequence
                      : ""
                  }
                  onChange={(event) =>
                    handleInputChange(
                      formProperties.sequence.value,
                      event.target.value
                    )
                  }
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={formProperties.sequence.errorMessage !== ""}
                  helperText={formProperties.sequence.errorMessage}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  disabled={!isEditing}
                  labelPlacement="start"
                  label={formProperties.required.label}
                  control={
                    <Checkbox
                      checked={
                        activity !== undefined &&
                          activity.required !== undefined
                          ? activity.required
                          : false
                      }
                      onChange={() =>
                        handleInputChange(
                          formProperties.required.value,
                          !activity.required
                        )
                      }
                      name={formProperties.required.value}
                      color="primary"
                    />
                  }
                />
                <FormControlLabel
                  disabled={!isEditing}
                  labelPlacement="start"
                  label={formProperties.oneTimeAnswer.label}
                  control={
                    <Checkbox
                      checked={
                        activity !== undefined &&
                          activity.oneTimeAnswer !== undefined
                          ? activity.oneTimeAnswer
                          : false
                      }
                      onChange={() =>
                        handleInputChange(
                          formProperties.oneTimeAnswer.value,
                          !activity.oneTimeAnswer
                        )
                      }
                      name={formProperties.oneTimeAnswer.value}
                      color="primary"
                    />
                  }
                />
                <FormControlLabel
                  disabled={!isEditing}
                  labelPlacement="start"
                  label={formProperties.active.label}
                  control={
                    <Checkbox
                      checked={
                        activity !== undefined && activity.active !== undefined
                          ? activity.active
                          : true
                      }
                      onChange={() =>
                        handleInputChange(
                          formProperties.active.value,
                          !activity.active
                        )
                      }
                      name={formProperties.active.value}
                      color="primary"
                    />
                  }
                />
              </Grid>
            </Grid>
          </CustomCard>
        </Box>
        {/* Only display choices, rewards and targets if the activity is existing e.g has an id */}
        {activity.id !== undefined && activity.id !== "" && (
          <Fragment>
            <Box className={styles.contentGrid}>
              {getListData(
                activity.choices,
                "Choices",
                "choices",
                "Add choice",
                () =>
                  props.history.push(
                    `/activity-groups/${activityGroupId}/${activity.id}/choice/new?isRanging=${isRanging}&activity=${activity.name}`
                  )
              )}
            </Box>
            <Box className={styles.contentGrid}>
              {getListData(
                activity.rewards,
                "Rewards",
                "rewards",
                "Add reward",
                () =>
                  props.history.push(
                    `/activity-groups/${activityGroupId}/${activity.id}/reward/new?activity=${activity.name}`
                  )
              )}
            </Box>
            {/* Target upload has extra buttons on its toolbar. */}
            <Box className={styles.contentGrid}>
              {getListData(
                activity.targets,
                "Targets",
                "targets",
                "Add target",
                () =>
                  props.history.push(
                    `/activity-groups/${activityGroupId}/${activity.id}/target/new?activity=${activity.name}`
                  ),
                "Download Targets",
                "Upload Targets",
                () => { },
                () => { }
              )}
            </Box>
          </Fragment>
        )}
      </Fragment>
    );
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <Box>

      <HeaderBlock
        title={`${isExistingActivity ? activity.name : "New Activity"}`}
        right={
          <Grid container spacing={1} alignItems="center" justify="flex-end">
            <Grid item>
              <ButtonGroup>
                {isEditing ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleCancelEditButtonClicked(true)}
                  >
                    Cancel
                  </Button>
                ) : null}
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    if (!isEditing) {
                      setIsEditing(true);
                      setUneditedActivity(activity);
                    } else if (isEditing && isExistingActivity) {
                      handleUpdateClicked();
                    } else {
                      handleSubmitClicked();
                    }
                  }}
                >
                  {isEditing
                    ? isExistingActivity
                      ? "Done"
                      : "Submit"
                    : "Edit"}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        }
      />

      {cancelEditingAlert()}

      <PageContainer>{renderData()}</PageContainer>

      {
        showTargetUploadModal ?
          <Modal
            title={""}
            open={true}
            fullWidth={true}
            fixedHeight={false}
            actions={
              <Box>
                <Button
                  color="secondary"
                  disabled={CSVInvalid}
                  onClick={() => {
                    let i = 1;
                    setCSVInvalid(true); // force off the submit button, prevents multiclicks. It re-enables after callback completion.

                    csvData.forEach((item) => {
                      item.Id = i++;
                      item.ModifiedBy = loggedInUser.userId;
                      item.CreatedBy = loggedInUser.userId;
                    });

                    for (let i = 0; i < csvData.length; i++) {
                      const obj = csvData[i];

                      for (const key in obj) {
                        if (obj.hasOwnProperty(key) && obj[key] === null) {
                          obj[key] = "";
                        }
                      }
                    }

                    dispatch(
                      replaceActivityTargets(activity.id, csvData, () => {
                        setShowTargetUploadModal(false);
                        setCSVInvalid(false);
                        props.history.go(0);
                      })
                    );

                  }}>
                  Submit
                </Button>
                <Button color="secondary" onClick={() => setShowTargetUploadModal(false)}>
                  Cancel
                </Button>
              </Box>
            }
          >
            <MigrationUploadSegment
              csvData={csvData}
              setCsvData={setCsvData}
              setDataChanged={setDataChanged}
              title="CSV Upload & Replace"
              targetsEdited={targetsEdited}
              setTargetsEdited={setTargetsEdited}
              isEditing={isEditing}
              setFormEdited={setFormEdited}
              description={["Please upload a CSV of Activity Targets.", <b> All existing Activity Targets will be set to inactive unless explicitly updated within the CSV uploaded.</b>]}
              templateFileName="PPP_Target_Template.csv"
              expectedColumns={PPPTargetExpectedColumns}
              setCSVInvalid={setCSVInvalid}
            />
          </Modal>
          : null
      });
    </Box>
  );
};

const hoc = withRouter(ViewActivityPage);

// EXPORT COMPONENT
export { hoc as ViewActivityPage };
